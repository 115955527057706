import React from 'react';
import 'lity/dist/lity.min.css';

function DemoVideo() {
  return (
    <div className="demo-video-container" style={styles.container}>
      <h1 style={styles.title}>Onboarding Video</h1>
      <p style={styles.description}>
        This onboarding video provides a comprehensive walkthrough of the entire application. 
        It demonstrates key features, functionalities, and the steps to get started seamlessly. 
        Whether you're a first-time user or someone looking to maximize the application's potential, 
        this video will guide you through everything you need to know. Sit back, watch, and explore 
        the powerful capabilities designed to make your experience intuitive and efficient.
      </p>
      <video
        controls
        style={styles.video}
        src="https://gqi-s3.s3.ap-south-1.amazonaws.com/documents/1731923781863_demoGqi.mp4"
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

// Inline styles for simplicity (you can replace with CSS classes if needed)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    // background: 'linear-gradient(135deg, #1d3557, #457b9d)', // Gradient background
    color: '#fff', // Set text color to white for better visibility
    padding: '20px',
    textAlign: 'center',
  },
  title: {
    marginBottom: '10px',
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#fff', // Match title text color with the overall theme
  },
  description: {
    marginBottom: '20px',
    fontSize: '16px',
    color: '#f0f0f0', // Slightly lighter color for readability
    maxWidth: '800px',
    lineHeight: '1.5',
  },
  video: {
    width: '80%',
    maxWidth: '800px',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  },
};

export default DemoVideo;
