import React from "react";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AiOutlineFileSearch } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";

function UserTable({
  data,
  columns,
  pageHeading,
  handleAddShow,
  handleEditShow,
  handleDeleteShow,
  handleDocsShow,
  currentPage,
  totalPages,
  handlePageChange,
  handleRowClick, // Accept handleRowClick as a prop
  lastDocumentElementRef
}) {
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">{pageHeading}</li>
          </ul>
          <h1 className="page-header mb-0">{pageHeading}</h1>
        </div>
      </div>

      <div className="row">
        {data.length > 0 ? (
          data.map((doc, index) => (
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" key={index} onClick={()=>handleRowClick(doc)}>
            <Card
              className="h-100 d-flex flex-column"
              style={{
                height: "300px", // Fixed card height
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between", // Ensure content spacing
                }}
              >
                <div
                  className="d-flex fw-bold small mb-3"
                  onClick={() => handleRowClick(doc)}
                >
                  <span className="flex-grow-1">{doc?.title}</span>
                </div>
                {/* <iframe
                  src={doc?.docs[0]}
                  width="100%"
                  height="150px" // Fixed iframe height
                  style={{
                    borderRadius: "4px",
                  }}
                ></iframe> */}
                <p
                  style={{
                    marginTop: "10px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    // whiteSpace: "nowrap", // Truncate long descriptions
                  }}
                >
                  {doc?.description || "No Description"}
                </p>
              </CardBody>
            </Card>
          </div>
          
          ))
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "100vh", textAlign: "center" }}
          >
            <AiOutlineFileSearch size={50} color="#ccc" />
            <h2>No data Found</h2>
          </div>
        )}
        <div ref={lastDocumentElementRef} />
      </div>
    </div>
  );
}

export default UserTable;



