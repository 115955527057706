import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the authentication slice
const initialState = {
  user: null,
  isAuthenticated: false,
  error: null,
  firstTime:false

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    logoutSuccess: (state, action) => {
      
      state.isAuthenticated = false;
      state.user = null
    
    },
    firstTimeLoginStatusChange: (state, action) => {
    
      state.firstTime =  action.payload;
  
    
    }
  }
});

// Export actions
export const { loginSuccess, loginFailure,logoutSuccess,firstTimeLoginStatusChange } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;