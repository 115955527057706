import React, { useEffect, useState } from 'react';
import axios from 'axios';

function GetAllNotification() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const token = sessionStorage.getItem('token');

  // Fetch all notifications
  const fetchNotifications = async () => {
    setLoading(true); // Set loading to true when starting to fetch
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNotifications(response?.data || []);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  // Mark notification as read when clicked
  const markAsRead = async (notificationId) => {
    try {
      // Send a request to update the notification status
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/notifications/${notificationId}/read`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Refresh the notifications list after updating
      fetchNotifications();
      fetchUnreadCount();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const fetchUnreadCount = async () => {
    try {
      const unreadCountData = await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/unread-count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error fetching unread count:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="mb-4">All Notifications</h2>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        notifications.length > 0 ? (
          <ul className="list-group">
            {notifications.map((notification, index) => (
              <li
                key={index}
                className="list-group-item"
                onClick={() => notification.status === 'unread' && markAsRead(notification.id)}  // Only call markAsRead if the status is 'unread'
                style={{
                  cursor: 'pointer',
                  backgroundColor: notification.status === 'unread' ? '#d3d3d3' : 'transparent',  // Light grey for unread notifications
                  padding: '10px',
                  marginBottom: '10px',
                  borderRadius: '4px'
                }}
              >
                <h5>{notification.name} of email {notification.email} has requested to become GQI member</h5>
                {/* Message below the title */}
                <p className="notification-message">{notification.message}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No notifications found.</p>
        )
      )}
    </div>
  );
}

export default GetAllNotification;
