import React, { useEffect, useContext, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PagesContactUs() {
  const context = useContext(AppSettings);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');
    
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
    };
    
    // eslint-disable-next-line
  }, []);

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) errors.name = "Name is required.";
    if (!email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format.";
    }
    if (!message.trim()) errors.message = "Message is required.";
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    const payload = { name, email, message };
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contact-us`, payload);
      if (response.status === 200) {
        toast.success('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
        setErrors({});
      } else {
        toast.error('Failed to send message. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="contact-us d-flex align-items-center justify-content-center min-vh-100">
      <ToastContainer />
      <div className="contact-us-content text-center">
        <h2 className="mb-4">Contact Us</h2>
        <p className="mb-4">We'd love to hear from you! Please fill out the form below to get in touch.</p>
        <form className="text-start" onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">
              Your Name <span className="text-danger">*</span>
            </label>
            <input 
              type="text" 
              className={`form-control ${errors.name ? 'is-invalid' : ''}`} 
              placeholder="Your Name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">
              Your Email <span className="text-danger">*</span>
            </label>
            <input 
              type="email" 
              className={`form-control ${errors.email ? 'is-invalid' : ''}`} 
              placeholder="Your Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>
          <div className="mb-4">
            <label className="form-label">
              Your Message <span className="text-danger">*</span>
            </label>
            <textarea 
              className={`form-control ${errors.message ? 'is-invalid' : ''}`} 
              placeholder="Your Message" 
              rows="4" 
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
          </div>
          <button type="submit" className="btn btn-outline-theme btn-lg w-100">SEND MESSAGE</button>
        </form>
        <div className="text-center small text-inverse text-opacity-50 mt-4">
          &copy; 2024 Global Quantum Intelligence, LLC. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default PagesContactUs;
