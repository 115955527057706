import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the authentication slices
const initialState = {
 
  signUpInfo: {},

};

const singUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    signupStore: (state, action) => {
    
      state.signUpInfo = action.payload;
    
    },
  
  }
});

// Export actions
export const { signupStore } = singUpSlice.actions;

// Export the reducer
export default singUpSlice.reducer;