import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Modal, Spinner } from "react-bootstrap";

let zIndexCounter = 1000;

function UserDashboard() {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIframeSrc, setModalIframeSrc] = useState("");
  const [draggedCardIndex, setDraggedCardIndex] = useState(null);
  const [cardZIndices, setCardZIndices] = useState({});
  const [cardSizes, setCardSizes] = useState({});

  const sizeMap = {
    small: { width: "300px", height: "300px" },  // Adjust size as needed
    medium: { width: "500px", height: "500px" }, // Adjust size as needed
    large: { width: "1000px", height: "1000px" },  // Adjust size as needed
  };

  const token = sessionStorage.getItem("token");
  const containerRef = useRef(null);
  const observerRef = useRef();

  useEffect(() => {
    fetchLikedDocuments(currentPage);
  }, [currentPage]);

  const fetchLikedDocuments = (page) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/likedDocuments`, {
        params: { page, limit: 10 },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedDocuments = response.data.likedDocuments.map((doc) => {
          // Determine card size based on embedWindowSize field
          const size = sizeMap[doc?.embedWindowSize] || sizeMap?.small; // Fallback to small size
          return {
            ...doc,
            size, // Attach the determined size to each document
          };
        });
        setDocuments((prevDocs) => [...prevDocs, ...fetchedDocuments]);
        // setDocuments((prevDocs) => [...prevDocs, ...response.data.likedDocuments]);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(() => {
        toast.error("No Liked Documents Found");

      })
      .finally(() => {
        setLoading(false); // Hide loading spinner
      })
      ;
  };

  const toggleBookmark = async (index) => {
    const document = documents[index];
    const { id: documentId } = document;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmarkDocument`,
        { documentId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedDocuments = [...documents];
      updatedDocuments[index] = {
        ...document,
        saved: response.data.saved,
      };
      setDocuments(updatedDocuments);
    } catch (error) {
      console.error("Error bookmarking document:", error);
    }
  };

  const lastDocumentElementRef = (node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observerRef.current.observe(node);
  };

  const handleFullscreenClick = (doc) => {
    setModalIframeSrc(doc?.docs[0]);
    setModalTitle(doc.title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDragStart = (e, index) => {
    if (e.target.className.includes("resize-handle")) return;

    e.preventDefault();
    e.stopPropagation();

    e.preventDefault();
    setDraggedCardIndex(index);

    const cardElement = e.currentTarget;
    const iframe = cardElement.querySelector("iframe");


    iframe.style.pointerEvents = "none";


    const currentLeft = parseFloat(cardElement.style.left) || 0;
    const currentTop = parseFloat(cardElement.style.top) || 0;

    const offsetX = e.clientX - currentLeft;
    const offsetY = e.clientY - currentTop;

    cardElement.style.cursor = "grabbing";

    setCardZIndices((prev) => ({
      ...prev,
      [index]: ++zIndexCounter,
    }));

    const handleMouseMove = (moveEvent) => {
      const newLeft = moveEvent.clientX - offsetX;
      const newTop = moveEvent.clientY - offsetY;


      cardElement.style.position = "relative";
      cardElement.style.left = `${newLeft}px`;
      cardElement.style.top = `${newTop}px`;
    };

    const handleMouseUp = () => {
      iframe.style.pointerEvents = "auto";
      setDraggedCardIndex(null);

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  // const handleResize = (e, index, direction) => {
  //   e.preventDefault();

  //   const cardElement = e.currentTarget.parentElement;
  //   const initialWidth = cardElement.offsetWidth;
  //   const initialHeight = cardElement.offsetHeight;
  //   const initialLeft = cardSizes[index]?.left || cardElement.offsetLeft;
  //   const initialTop = cardSizes[index]?.top || cardElement.offsetTop;
  //   const startX = e.clientX;
  //   const startY = e.clientY;

  //   const handleMouseMove = (moveEvent) => {
  //     const deltaX = moveEvent.clientX - startX;
  //     const deltaY = moveEvent.clientY - startY;

  //     let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
  //     let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);

  //     let newLeft = initialLeft + (direction.includes("left") ? deltaX : 0);
  //     let newTop = initialTop + (direction.includes("top") ? deltaY : 0);

  //     // Minimum size constraints
  //     newWidth = Math.max(300, newWidth);
  //     newHeight = Math.max(300, newHeight);

  //     // Maximum size constraints
  //     const containerBounds = containerRef.current.getBoundingClientRect();
  //     const maxWidth = Math.max(containerBounds.width, newWidth); // Allow it to go beyond the container width if needed
  //     const maxHeight = Math.max(containerBounds.height, newHeight);

  //     // Constrain the new width and height to the maximum allowed
  //     newWidth = Math.min(newWidth, maxWidth);
  //     newHeight = Math.min(newHeight, maxHeight);

  //     // Update card sizes and position
  //     setCardSizes((prev) => ({
  //       ...prev,
  //       [index]: {
  //         width: newWidth,
  //         height: newHeight,
  //         left: direction.includes("left") ? newLeft : prev[index]?.left || initialLeft,
  //         top: direction.includes("top") ? newTop : prev[index]?.top || initialTop,
  //       },
  //     }));
  //   };

  //   const handleMouseUp = () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     window.removeEventListener("mouseup", handleMouseUp);
  //   };

  //   window.addEventListener("mousemove", handleMouseMove);
  //   window.addEventListener("mouseup", handleMouseUp);
  // };

  useEffect(() => {
    if (!containerRef.current) return;

    let maxWidth = 0;
    let maxHeight = 0;

    // Calculate the max dimensions based on card sizes and positions
    documents.forEach((_, index) => {
      const cardSize = cardSizes[index] || {};
      const cardWidth = cardSize.width || sizeMap.small.width.replace("px", "");
      const cardHeight = cardSize.height || sizeMap.small.height.replace("px", "");
      const cardLeft = cardSize.left || 0;
      const cardTop = cardSize.top || 0;

      maxWidth = Math.max(maxWidth, cardLeft + parseInt(cardWidth, 10));
      maxHeight = Math.max(maxHeight, cardTop + parseInt(cardHeight, 10));
    });

    // Update the container's size
    containerRef.current.style.minWidth = `${maxWidth}px`;
    containerRef.current.style.minHeight = `${maxHeight}px`;
  }, [documents, cardSizes]);

  const handleResize = (e, index, direction) => {
    e.preventDefault();

    const cardElement = e.currentTarget.parentElement;
    const initialWidth = cardElement.offsetWidth;
    const initialHeight = cardElement.offsetHeight;
    const initialLeft = cardSizes[index]?.left || cardElement.offsetLeft;
    const initialTop = cardSizes[index]?.top || cardElement.offsetTop;
    const startX = e.clientX;
    const startY = e.clientY;

    const containerElement = containerRef.current;

    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
      let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);

      let newLeft = initialLeft + (direction.includes("left") ? deltaX : 0);
      let newTop = initialTop + (direction.includes("top") ? deltaY : 0);

      // Enforce minimum size
      newWidth = Math.max(300, newWidth);
      newHeight = Math.max(300, newHeight);

      // Update card size
      setCardSizes((prev) => ({
        ...prev,
        [index]: {
          width: newWidth,
          height: newHeight,
          left: direction.includes("left") ? newLeft : prev[index]?.left || initialLeft,
          top: direction.includes("top") ? newTop : prev[index]?.top || initialTop,
        },
      }));

      // Prevent container overflow into browser window
      if (containerElement) {
        const cardRect = cardElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();

        // Expand the container if needed, but avoid browser scrollbars
        containerElement.style.height = `${Math.max(
          containerElement.scrollHeight,
          cardRect.bottom
        )}px`;

        if (cardRect.right > containerRect.right) {
          containerElement.style.width = `${Math.max(
            containerRect.width,
            cardRect.right - containerRect.left
          )}px`;
        }
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);

      cardElement.querySelector("iframe").style.pointerEvents = "auto";
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };


  return (
    <div
      ref={containerRef}
      style={{
        position: "relative", overflow: "hidden", // Prevent overflow
        padding: "0px 10px",
      }}
    >
      <Card className="mb-3" style={{ width: '100%' }}>
        <CardBody style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px' }}> {/* Set a fixed height and use flexbox for centering */}
          <div className="d-flex align-items-center"> {/* Ensure vertical alignment of icon and text */}
            <AiOutlineFileSearch size={24} style={{ marginRight: "10px", color: "#6c757d" }} />
            <span className="text-center" style={{ textAlign: 'center' }}>
              Welcome to the GQI Intelligence Portal! As you explore GQI Intelligence, bookmark your favorite views to create your own personalized analysis dashboard
            </span>
          </div>
        </CardBody>
      </Card>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner animation="border" variant="primary" /> {/* Loader */}
        </div>
      ) : <> <div className="row" style={{ position: "relative", minWidth: '100%' }}>
        {documents.length > 0 ? (
          documents.map((doc, index) => (
            <div
              className={`mb-4 ${doc.size.width === "1000px"
                ? "col-md-12"
                : "col-lg-6 col-md-12"
                }`}
              key={index}
              onMouseDown={(e) => handleDragStart(e, index)}
            >
              <div className="" style={{ position: "relative", minHeight: cardSizes[index]?.height ? doc?.size?.height || '500px' : doc?.size?.height }}>
                <Card
                  className="mb-3"
                  style={{
                    position: "absolute",
                    zIndex: cardZIndices[index] || 1,
                    width: cardSizes[index]?.width || "100%",
                    height: cardSizes[index]?.height || "100%",
                    left: cardSizes[index]?.left || 0,
                    top: cardSizes[index]?.top || 0,
                    border:
                      "2px solid transparent",

                  }}
                >

                  <CardBody>
                    <div className="d-flex fw-bold small mb-3">
                      <span className="flex-grow-1">{doc?.title}</span>

                      <i
                        className={
                          doc.saved ? "fas fa-bookmark" : "far fa-bookmark"
                        }
                        style={{
                          cursor: "pointer",
                          marginRight: "10px",
                          marginTop: "3px",
                        }}
                        onClick={() => toggleBookmark(index)}
                      ></i>
                      <i
                        className="bi bi-fullscreen"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFullscreenClick(doc)}
                      ></i>
                    </div>
                    <iframe
                      src={doc?.docs[0]}
                      width="100%"
                      height="90%"
                      style={{
                        borderRadius: "4px",
                      }}
                    ></iframe>

                    {/* Resize Handles */}
                    <div
                      className="resize-handle"
                      onMouseDown={(e) =>
                        handleResize(e, index, "top-left")
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        cursor: "nwse-resize",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "transparent",
                      }}
                    ></div>
                    <div
                      className="resize-handle"
                      onMouseDown={(e) =>
                        handleResize(e, index, "top-right")
                      }
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        cursor: "nesw-resize",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "transparent",
                      }}
                    ></div>
                    <div
                      className="resize-handle"
                      onMouseDown={(e) =>
                        handleResize(e, index, "bottom-left")
                      }
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        cursor: "nesw-resize",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "transparent",
                      }}
                    ></div>
                    <div
                      className="resize-handle"
                      onMouseDown={(e) =>
                        handleResize(e, index, "bottom-right")
                      }
                      style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        cursor: "nwse-resize",
                        width: "10px",
                        height: "10px",
                        backgroundColor: "transparent",
                      }}
                    ></div>
                  </CardBody>
                </Card>
              </div>
            </div>
          ))
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ minHeight: "100vh", textAlign: "center" }}
          >
            <AiOutlineFileSearch size={50} color="#ccc" />
            <h2>No Liked Documents Found</h2>
          </div>


        )}
        <div ref={lastDocumentElementRef} />
      </div>

        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          className="custom-fullscreen-modal"
          style={{ padding: 0, margin: 0 }} // No extra spacing
        >
          <Modal.Body
            style={{
              padding: 0,
              margin: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh', // Full screen height
              width: '820px', // Ensure full width
              overflow: 'hidden', // Prevent unnecessary scrollbars
              position: 'relative', // For the absolute positioning of the close button
            }}
          >


            {/* Iframe to cover the entire modal body */}
            <iframe
              src={modalIframeSrc}
              title="Fullscreen Document"
              style={{
                border: 'none',
                width: '100%', // Full width of modal body
                height: '100%', // Full height of modal body
              }}
            ></iframe>
          </Modal.Body>
        </Modal>


      </>
      }


      <ToastContainer />
    </div>
  );
}

export default UserDashboard;
