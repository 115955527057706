import React, { useEffect, useState } from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath,Link,useNavigate } from 'react-router-dom';
import axios from 'axios';  // Import axios
import { useSelector, useDispatch } from 'react-redux';
import { setSectionName } from '../../redux/slices/setSectionNames';
import { setThemes} from '../../redux/slices/themeSlice.js';

// NavItem component for rendering individual menu items
function NavItem({ menu,expandedMenu, setExpandedMenu ,firstRenderComplete,setFirstRenderComplete, ...props }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

 

  // const [isExpanded, setIsExpanded] = useState(true);

  
  let path = menu.path ? menu.path : '';
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname });
  let location = useLocation();
  let match2 = matchPath({ path: path, end: false }, location.pathname);

  if (menu.is_header) {
    return <div className="menu-header" style={{ marginLeft: "17px" }}>{menu.title}</div>;
  }

  if (menu.is_divider) {
    return <div className="menu-divider"></div>;
  }

  const handleItemClick=(menu)=>{
    if (menu.children?.length > 0) {
    setFirstRenderComplete(false)
    }
    console.log("parentmenu",menu)
  if(menu.parentId){
 
  var sectionInfo={parentName:menu.parentInfo?.name,childName:menu?.title}

  dispatch(setSectionName(sectionInfo));


  }
  if(!menu.parentId && (menu.title.includes("Quantum Market Model")||menu.title.includes("Watch lists")||menu.title.includes("State of Play"))){
  console.log("hello12")
    var sectionInfo={parentName:menu?.title}
    dispatch(setSectionName(sectionInfo));
    navigate(`${menu.path}`);
  }
 


}


const isExpanded = firstRenderComplete ?  true:expandedMenu === menu.title ;
// const isExpanded = expandedMenu === menu.title ;


let icon = null;
if (menu.children?.length > 0) {
  icon = (
    <div className="menu-icon">
      <i className={`bi ${isExpanded ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
    </div>
  );
}

  // let icon = menu.hasCrown ? null : menu.icon && <div className="menu-icon"><i className={menu.icon}></i></div>;
  let img = menu.hasCrown ? null : menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
  let crownIcon = menu.hasCrown && <div className="menu-icon"><i className="bi bi-gem"></i></div>;
  let caret = (menu.children && !menu.badge) && <div className="menu-caret"><b className="caret"></b></div>;
  let label = menu.label && <span className="menu-label ms-5px">{menu.label}</span>;
  let badge = menu.badge && <div className="menu-badge">{menu.badge}</div>;
  let highlight = menu.highlight && <i className="fa fa-paper-plane text-theme"></i>;
  let title = menu.title && <div className="menu-text">{menu.title} {label} {highlight}</div>;

  return (
    <div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children?.length >0 ? ' has-sub' : '')}   onClick={(e) => {
      e.stopPropagation();// Prevent default navigation if needed (optional)

      if (menu.children?.length > 0) {
    
        setExpandedMenu(isExpanded ? null : menu.title);
      }
      handleItemClick(menu); // Call the function only on click
    }}>
      <NavLink className="menu-link" to={menu.hasCrown ? '/pages/premiumAccess' : menu.path} {...props} >
        {img} {icon} {crownIcon} {title}
      </NavLink>

      {menu.children && isExpanded && (
        <div className="menu-submenu">
          {menu.children.map((submenu, i) => (
            <NavItem key={i} menu={submenu}   expandedMenu={expandedMenu}
              setExpandedMenu={setExpandedMenu}   firstRenderComplete={firstRenderComplete} />
          ))}
        </div>
      )}
    </div>
  );
}

// SidebarNav component for rendering the entire sidebar menu
function SidebarNav() {
  const [allPermissions, setAllPermissions] = useState([]); // State to hold all permissions from API
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [firstRenderComplete, setFirstRenderComplete] = useState(true);
  const userPermissions = useSelector((state) => state.getUser.user?.Subscription?.Permissions || []);

  useEffect(() => {
    // Fetch all permissions from API using axios
    const fetchPermissions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllPermissions`); // Replace with your actual API endpoint
        console.log("permissions", response);
        setAllPermissions(response.data); // Assuming the API response has a 'permissions' field
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
    // setTimeout(() => {
    //   setFirstRenderComplete(false); // Set to false after initial render
    // }, 3000);
    
  }, []);

  // Function to map backend data to frontend menu format
  const mapBackendToFrontendMenu = (permissions) => {
    const menuMap = {};

    permissions?.forEach((permission) => {
      console.log("permissionmap==>>",permission)
      
      if (!permission.parentId) {

        if(permission.name.includes("Quantum Market Model")||permission.name.includes("Watch lists")||permission.name.includes("State of Play")){
          menuMap[permission.id] = {
             path: `${permission.routeName}`,
            icon: 'bi bi-bag-check', 
            title: permission.name,
            children: [],
            hasCrown: !userPermissions.some(userPerm => userPerm.id === permission.id), // Add crown if user does not have this permission
            parentId:permission.parentId,
            sequenceNumber:permission.sequenceNumber
          };
        }else{
          menuMap[permission.id] = {
            // path: `/dashboard`,
            icon: 'bi bi-bag-check', 
            title: permission.name,
            children: [],
            hasCrown: !userPermissions.some(userPerm => userPerm.id === permission.id), // Add crown if user does not have this permission
            parentId:permission.parentId,
            sequenceNumber:permission.sequenceNumber
          };
        }
       
      } else {
        if (menuMap[permission.parentId]) {
         
          menuMap[permission.parentId].children.push({
            path: `${permission.routeName}`,
            title: permission.name,
            hasCrown: !userPermissions.some(userPerm => userPerm.id === permission.id), // Add crown for child if user does not have this permission
         parentId:permission.parentId,
         parentInfo:permission?.parentPermission,
         sequenceNumber:permission.sequenceNumber
          });
        }
      }
    });

  
    const sortedMenuMap = Object.values(menuMap).sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    // Convert menuMap to array and sort children to place items with crown at the end
    const sortedMenus = sortedMenuMap?.map(menu => ({
      ...menu,
      children: menu.children.sort((a, b) => a.hasCrown - b.hasCrown), // Sort children by hasCrown
    }));

   

    return sortedMenus;
  };

  // Map all permissions and check against user's permissions
  const menus = mapBackendToFrontendMenu(allPermissions);

  return (
    <div className="menu">
      <div className='menu-item active'>
      <Link className="menu-link" to={'/dashboard'} >
      {/* <div className="menu-icon"><i className="bi bi-bag-check"></i></div>  */}
      <div className="menu-text">Dashboard

      </div>
      </Link>

     
    </div>
      {menus.map((menu, i) => (
        
        <NavItem key={i} menu={menu} 
        expandedMenu={expandedMenu}
        setExpandedMenu={setExpandedMenu}
        firstRenderComplete={firstRenderComplete}
        setFirstRenderComplete={setFirstRenderComplete}
        />
      ))}
    </div>
  );
}

export default SidebarNav;
