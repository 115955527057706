import React, { useEffect, useContext, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import { loginUser } from "../../apis/userApi.js";
import { loginSuccess, loginFailure,firstTimeLoginStatusChange } from "../../redux/slices/authSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserSuccess, getUserFailure } from "../../redux/slices/getUser.js";
// Import FontAwesome for icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

//
function PagesLogin() {
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // State for email, password, errors, and password visibility toggle
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    // context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
  }, []);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const userPermissions = useSelector(
    (state) => state.getUser.user?.UserPermissions || []
  );

  async function handleSubmit(event) {
    event.preventDefault();
    setEmailError("");
    setPasswordError("");
    setApiError("");

    let isValid = true;
    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    }

    if (!isValid) {
      clearErrors();
      return
    }

    try {

      
      const loginResponse = await loginUser(email, password, rememberMe);

      if(loginResponse?.firstTimeLogin){
        dispatch(firstTimeLoginStatusChange(true));
        setTimeout(() => {
          dispatch(firstTimeLoginStatusChange(false));
        }, 5000);

      }

      if (loginResponse.token) {
        sessionStorage.setItem("token", loginResponse.token);
        dispatch(loginSuccess(loginResponse));
        await fetchUserData(loginResponse);
      } else {
        setApiError(loginResponse.message || "Login failed");
        dispatch(loginFailure(loginResponse.message || "Login failed"));
        clearErrors();
      }
    } catch (error) {
      setApiError(error.message || "An error occurred");
      dispatch(loginFailure(error.message || "An error occurred"));
      clearErrors();
    }
  }

  const fetchUserData = async (userData) => {
    try {
      let response;
      if (
        userData?.userType === "superAdmin" ||
        userData?.userType === "subAdmin"
      ) {
        response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/getAdminUser`,
          {
            headers: { Authorization: `Bearer ${userData.token}` },
          }
        );
        navigate(`/pages/${response.data.UserPermissions[0]?.routeName}`);
      } else {
        response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getUser`, {
          headers: { Authorization: `Bearer ${userData.token}` },
        });
          navigate('/dashboard');
      }
      dispatch(getUserSuccess(response.data));
    } catch (error) {
      dispatch(getUserFailure(error.message));
    }
  };

  const clearErrors = () => {
    setTimeout(() => {
      setEmailError("");
      setPasswordError("");
      setApiError("");
    }, 2000);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (redirect) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login-app-content">
      <div className="row justify-content-center" style={{ minHeight:"100vh"}}>
        <div className="col-md-12">
          <Link to="/">
            <img
              src={
                theme === "dark"
                  ? "/assets/img/dashboard/dark_logo_web.jpg"
                  : "/assets/img/dashboard/light_logo_web.png"
              }
              style={{
                height: "60px",
              }}
              alt="Brand Logo"
            />
          </Link>
        </div>
        <div className="col-md-4 col-12" style={{alignItems:"center", }}>

        <div className="mb-4">
    <video
     
      autoPlay
      muted
      loop
      style={{
        width: "100%",
        borderRadius: "10px",
        marginBottom: "20px",
      }}
    >
      <source
        src="https://gqi-s3.s3.ap-south-1.amazonaws.com/documents/1733650732297_videoLogin.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
  </div>
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Sign In</h1>
            <div className="text-inverse text-opacity-50 text-center mb-4">
              For your protection, please verify your identity.
            </div>

            {apiError && <div className="alert alert-danger">{apiError}</div>}

            <div className="mb-3">
              <label className="form-label">
                Email Address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <div className="text-danger">{emailError}</div>}
            </div>

            <div className="mb-3">
              <div className="d-flex">
                <label className="form-label">
                  Password <span className="text-danger">*</span>
                </label>
                <Link
                  to="/pages/forgetpassword"
                  className="ms-auto text-inverse text-decoration-none text-opacity-50"
                >
                  Forgot password?
                </Link>
              </div>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="input-group-text"
                  onClick={togglePasswordVisibility}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={passwordVisible ? faEye : faEyeSlash}
                  />
                </span>
              </div>
              {passwordError && (
                <div className="text-danger">{passwordError}</div>
              )}
            </div>

            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="customCheck1"
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="customCheck1">
                  Remember me
                </label>
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3"
            >
              Sign In
            </button>
            <div className="text-center text-inverse text-opacity-50">
              Don't have an account yet?{" "}
              <Link to="/pages/pricing" style={{ textDecoration: "none" }}>
                Sign up
              </Link>
              .
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PagesLogin;
